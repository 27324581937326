@import "main.css";
.px-4.py-5.text-center.cta_banner.bg-sixthColor.position-relative > .background-image-div-opacity {
background-attachment: fixed;
}
.navbar-brand { height: 11vh !important; width: 11vw !important; }
.logo_nav { height: 10vh !important }
.animated-border-button:after {
background-color: #23A638;
}

#navigation-bar-hide {
opacity: 1 !important;
top: 0 !important;
}
.redirects-title-background {
background-color: #ffffff95;
}